$black: #000000;
$grey: #808080;
$light-grey: #d2d2d2;
$med-white: #e2e2e2;
$off-white: #f2f2f2;
$white: #ffffff;

.mobile-phone {
  margin: 0 auto;
  padding: 10px 10px 30px;
  height: max-content;
  min-height: 600px;
  box-shadow: 0 0 20px $med-white;
  border-radius: 30px;
  width: 100%;
}

.screen {
  width: 100%;
  min-height: 600px;
  max-height: 600px;
  background: $off-white;
  border-radius: 30px;
  overflow-y: auto;
  padding: 40px 20px;
}

.brove {
  width: 150px;
  height: 20px;
  background: $white;
  position: absolute;
  margin: 0 90px;
  border-radius: 0 0 20px 20px;
  display: none;
}

.speaker {
  width: 60px;
  height: 5px;
  background: $light-grey;
  display: block;
  margin: auto;
  margin-top: 5px;
  border-radius: 20px;
}

/* width */
.screen::-webkit-scrollbar {
  width: 1px;
}

/* Track */
.screen::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $grey;
  border-radius: 10px;
}

/* Handle */
.screen::-webkit-scrollbar-thumb {
  background: $black;
  border-radius: 10px;
}

.jsx-parser {
  padding: 30px 15px;
}

.wireframe-wrapper {
  width: 100%;
  .text-header {
    text-align: center;
    margin-bottom: 20px;
  }
}