$white: #ffffff;
.template-form {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 100px;
  &.opacity-5 {
    form {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  form {
    padding: 30px;
    border: 1px solid #e7e7e7;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    [for^="root__title"] {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    .ant-alert {
      display: none;
    }
    .schema-custom-button {
      width: 100%;
      background-color: #000;
      color: #fff;
      border-radius: 5px;
      position: fixed;
      bottom: 20px;
      box-shadow: 0px -4px 8px 0px #00000014;
      max-width: 500px;
      margin: auto;
      left: 0;
      bottom: 20px;
      right: 0;
      height: 40px;
      &:hover {
        border-color: #000;
        color: #000;
        background-color: #fff;
      }
    }
    .footer-buttons {
      width: 100%;
      position: fixed;
      bottom: 20px;
      max-width: 500px;
      margin: auto;
      left: 0;
      bottom: 20px;
      right: 0;
      max-width: 780px;
      .ant-space {
        width: 100%;
        .ant-space-item {
          width: 100%;
          .ant-btn {
            width: 100%;
            border-radius: 5px;
            height: 40px;
            background-color: $white;
          }
          .ant-btn-primary {
            width: 100%;
            background-color: #000;
            color: #fff;
            border-radius: 5px;
            height: 40px;
            justify-content: center;
            align-items: center;
            &:hover {
              border-color: #000;
              color: #000;
              background-color: #fff;
            }
          }
        }
      }
    }
    .form-group {
      .ant-form-item {
        .ant-form-item-row {
          .ant-form-item-label {
            label {
              height: unset;
              &.ant-form-item-required {
                &::before {
                  display: none;
                }
                &::after {
                  display: inline-block;
                  margin-right: 4px;
                  color: #ff4d4f;
                  font-size: 14px;
                  font-family: SimSun, sans-serif;
                  line-height: 1;
                  content: "*";
                }
              }
            }
          }
        }
      }
    }
    .ant-radio-group {
      display: flex;
      flex-direction: column;
    }
    textarea {
      &.ant-input {
        min-height: 120px;
      }
    }
    .custom-info-button {
      position: relative;
      .info-svg {
        position: absolute;
        top: 0px;
        right: 0;
      }
    }
  }
}
.description-modal {
  .ant-modal-content {
    .modal-header {
      margin-bottom: 20px;
    }
    .text-paragraph {
      text-align: justify;
      margin-bottom: 15px;
    }
    .ant-modal-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
