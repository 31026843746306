/*Color Palettes*/
$grey: #adb5bd;
$black: #000000;
$white: #ffffff;
$light-grey: #e7e7e7;
$dark-grey: #616161;
$light-black: #0d0d0d;
$blue: #006bf8;
$dark-blue: #3a8cd5;
$light-blue: #9cdcfe;
$grey-light: #b4b4b4;
$light-orange: #ce743a;
$med-black: #1f1f1f;

/* Basic editor styles */
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  pre {
    all: unset;

    code {
      all: unset;
      font-size: 16px;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba($light-black, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba($light-black, 0.1);
    margin: 2rem 0;
  }
  .tiptap-placeholder {
    &::before {
      color: $grey;
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
}

.brd-wrapper {
  margin: 0 auto;
  margin-bottom: 100px;
  max-width: 1000px;
  .btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  .footer-buttons {
    width: 100%;
    position: fixed;
    bottom: 20px;
    max-width: 500px;
    margin: auto;
    left: 0;
    bottom: 20px;
    right: 0;
    max-width: 800px;
    .ant-space {
      width: 100%;
      .ant-space-item {
        width: 100%;
        .ant-btn {
          width: 100%;
          border-radius: 5px;
          height: 40px;
          background-color: $white;
        }
        .ant-btn-primary {
          width: 100%;
          background-color: $black;
          color: $white;
          border-radius: 5px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            border-color: $black;
            color: $black;
            background-color: $white;
          }
        }
      }
    }
  }
  .tiptap-wrapper {
    &.no-event {
      pointer-events: none;
    }
  }
  .tiptap p.tiptap-placeholder::before {
    color: $grey;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
  .cursor-none {
    pointer-events: none;
    .add-btn-tiptap {
      display: none;
    }
  }
  .user-flow-header {
    display: flex;
    justify-content: flex-end;
  }
  .middle-content {
    display: flex;
    align-items: flex-start;
    column-gap: 15px;
    .flow-figures {
      min-height: 600px;
      padding: 15px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
    .left-aligned-button {
      min-height: 600px;
      position: absolute;
      left: 0;
      border-left: 0;
      box-shadow: none;
    }
    .bottom-aligned-button {
      position: absolute;
      bottom: -30px;
      left: 25%;
      width: 50%;
      border-bottom: 0;
      box-shadow: none;
    }
  }
}
.tiptap {
  padding: 80px;
  margin-top: 15px;
  border: 1px solid $light-grey;
  border-radius: 5px;
  .one-h1 {
    font-size: 24px;
  }
  .one-h2 {
    font-size: 18px;
  }
  .two-h2 {
    margin-left: 15px;
    font-size: 20px;
  }
  .two-h3 {
    margin-left: 15px;
    font-size: 16px;
  }
  .two-h4 {
    margin-left: 15px;
    font-size: 14px;
  }
  .three-h3 {
    margin-left: 30px;
    font-size: 16px;
  }
  .two-p {
    margin-left: 15px;
  }
  .three-p {
    margin-left: 30px;
  }
  .add-btn-tiptap {
    margin-top: -38px;
    margin-left: -35px;
  }
  p {
    font-size: 16px;
  }
}

.custom-editor {
  max-width: 900px;
  margin: 0 auto;
  border: 1px solid $light-grey;
  padding: 50px;
  border-radius: 15px;
  .ant-form-item {
    margin-bottom: 10px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }
    h1 {
      font-size: 18px;
    }
    h2 {
      font-size: 16px;
    }
    h3 {
      font-size: 14px;
    }
    .header-3 {
      font-size: 16px;
      font-weight: 600;
    }
    .btn-add {
      opacity: 0;
      transition: all ease 0.5s;
      &:hover {
        opacity: 1;
      }
    }
    textarea {
      border: 0;
      overflow: hidden;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .non-editable-header {
    &:disabled {
      background-color: transparent;
      color: $black;
      font-size: 18px;
      border: 0;
    }
  }
  .editable-textarea {
    border: 0;
    resize: none;
    overflow: hidden;
    padding: 0;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  .level-2 {
    margin-left: 15px;
    font-size: 16px;
    border: 0;
  }
  .level-3 {
    margin-left: 30px;
  }
  .ant-space-item {
    width: 100%;
  }
}

.ant-drawer-content-wrapper {
  .ant-drawer-body {
    .code-editor {
      .tiptap {
        border: 1px solid $grey;
        border-radius: 5px;
        min-height: 200px;
        padding: 15px;
        background-color: $black;
        p {
          color: $white;
          font-size: 14px;
          &::selection {
            background-color: $white;
            color: $black;
          }
        }
      }
      .ant-alert {
        margin-top: 10px;
      }
    }
  }
}

#dpreview {
  visibility: hidden;
  #preview {
    visibility: hidden !important; //To override inline css
  }
}

.ant-drawer {
  &.left-drawer,
  &.right-drawer {
    .ant-drawer-content-wrapper {
      top: 90px;
    }
    &.padding-0 {
      .ant-drawer-body {
        padding: 0;
      }
    }
  }
  &.bottom-drawer {
    .ant-drawer-content-wrapper {
      left: 200px;
      right: 140px;
    }
  }
  &.left-drawer {
    .screen-list-wrapper {
      .list-wrapper {
        margin-bottom: 30px;
        .text-header {
          cursor: pointer;
          &.active {
            color: $blue;
          }
        }
        ul {
          margin-left: 15px;
          li {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.user-flow-wrapper {
  margin: 0 auto;
  margin-bottom: 100px;
  .footer-buttons {
    width: 100%;
    position: fixed;
    bottom: 20px;
    max-width: 500px;
    margin: auto;
    left: 0;
    bottom: 20px;
    right: 0;
    max-width: 800px;
    z-index: 10001;
    .ant-space {
      width: 100%;
      .ant-space-item {
        width: 100%;
        .ant-btn {
          width: 100%;
          border-radius: 5px;
          height: 40px;
          background-color: $white;
        }
        .ant-btn-primary {
          width: 100%;
          background-color: $black;
          color: $white;
          border-radius: 5px;
          height: 40px;
          justify-content: center;
          align-items: center;
          &:hover {
            border-color: $black;
            color: $black;
            background-color: $white;
          }
        }
      }
    }
  }
  .tiptap-wrapper {
    &.no-event {
      pointer-events: none;
    }
  }
  .tiptap p.tiptap-placeholder::before {
    color: $grey;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
  .cursor-none {
    pointer-events: none;
    .add-btn-tiptap {
      display: none;
    }
  }
  .user-flow-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;
    .ant-btn-default {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .middle-content {
    display: flex;
    align-items: flex-start;
    column-gap: 15px;
    .flow-figures {
      min-height: 600px;
      padding: 15px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 80%;
      overflow-x: scroll;
    }
    .left-aligned-button {
      height: 100%;
      position: absolute;
      left: 0;
      border-left: 0;
      box-shadow: none;
    }
    .bottom-aligned-button {
      position: absolute;
      bottom: -30px;
      left: 25%;
      width: 50%;
      border-bottom: 0;
      box-shadow: none;
    }
  }
}

.code-editor-custom-wrapper {
  caret-color: $white !important; //To override inline style
  background-color: $med-black !important; //To override inline style
  font-size: 14px !important; //To override inline style
  .w-tc-editor-preview {
    min-height: 300px !important; //To override inline style
  }
  .code-line {
    color: $dark-blue !important; //To override inline style
    .token {
      &.keyword {
        color: $light-blue !important; //To override inline style
      }
      &.operator {
        color: $light-grey !important; //To override inline style
      }
      &.string {
        color: $light-orange !important; //To override inline style
      }
      &.maybe-class-name {
        color: $white !important; //To override inline style
      }
    }
  }
  textarea {
    &::selection {
      background-color: $black;
      color: $black;
    }
  }
}
