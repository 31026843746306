.add-project-modal {
  .ant-form-item {
    margin-bottom: 10px;
  }
  .submit-footer {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
.dashboard-wrapper {
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text-project {
      margin-bottom: 0;
    }
  }
}

.project-details-wrapper {
  .header-wrap {
    .title-project {
      margin-bottom: 0;
    }
  }
  .action-tabs {
    .ant-tabs {
      row-gap: 30px;
    }
    .ant-tabs-nav {
      max-width: fit-content;
      margin: 0 auto;
      margin-bottom: 50px;
      .ant-tabs-nav-list {
        background: #0000000f;
        padding: 0 20px;
        border-radius: 8px;
      }
    }
  }
}

.warning-modal {
  .warning-footer-btn {
    margin: 0 0 0 auto;
    border-radius: 4px;
  }
}