.header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  gap: 30px;
  align-items: center;
  .menu-wrapper {
    display: flex;
    gap: 30px;
    align-items: center;
    .text-logo {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0;
      min-width: fit-content;
    }
  }
  .profile-items {
    display: flex;
    align-items: center;
    gap: 20px;
    .text-profile {
      //styleName: Body/regular;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    .ant-avatar-image {
      cursor: pointer;
    }
    .ant-space-compact-block {
      width: unset;
    }
  }
}
.antd-loader {
  display: flex;
  justify-content: center;
}

.ant-result {
  .ant-result-extra {
    display: flex;
    justify-content: center;
  }
}
