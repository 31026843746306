* {
  margin: 0;
  padding: 0;
}
body {
  padding: 0 15px;
}
.container {
  max-width: 1600px;
  margin: 0 auto;
}
button {
  &.ant-btn-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }
}
