.login-form {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 30px;
  background: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0px 4px 12px 0px #00000040;
  border: 1px solid #cecece;
  width: 345px;
  @media (max-width: 467px) {
    width: 300px;
  }
  .text-login {
    text-align: center;
  }
  .btn-login {
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 23px;
    text-align: left;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    border: 1px solid #E2E2E2;
    width: 100%;
    span {
      color: #0000008A;
    }
  }
}
